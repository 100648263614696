import axios from "axios";
import {
  clearUserData,
  formattedToken,
  performLogutRedirect,
} from "../../../utils";
import { resetAllStates } from "../../utils";

import { store } from "../../store";

const baseUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "http://localhost:8000";
  } else if (window.location.host.includes("demo.management.pigello")) {
    return "https://v2alpha.api.pigello.se";
  } else if (window.location.host.includes("test.management.pigello")) {
    return "https://v2alpha.api.pigello.se";
  } else if (window.location.host.includes("v2.app")) {
    return "https://v2api.pigello.se";
  } else {
    return "https://v2api.pigello.se";
  }
};
export const axiosInstance = axios.create({
  baseURL: baseUrl(),
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = formattedToken();
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let status = 500;
    if (error.response) {
      status = error.response.status;
    }

    if (status === 401) {
      clearUserData();
      store.dispatch(resetAllStates());
      performLogutRedirect();
    }

    throw error;
  }
);

export const getCancelToken = () => {
  return axios.CancelToken.source();
};
