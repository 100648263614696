import React, { useEffect } from "react";
import "./sass/main.scss";

import { ConnectedRouter } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";

import { localLogin, setScreenSize } from "./store/app/actions";
import { history } from "./store/store";

import { ThemeProvider as SCTheme } from "styled-components";
import theme from "./theme";

import Routes from "./Routes";
import ScrollToTop from "./utils/ScrollToTop";
import { addAlphaScript, addBetaScript } from "./external/gtagScripts";
import { debounce } from "lodash";
import TOSAgreement from "./components/Account/Terms/TOSAgreement";
import { clearUserData, getToken } from "./utils";
import { handleSocketUpdates } from "./store/utils";
import { currentEnv, ENVIRONMENTS, SOCKET_URL } from ".";
import ToastList from "./components/Toasts/ToastList";
import { initIntercom } from "./external/intercom";
import moment from "moment";
import "moment/locale/sv";
import MfaSetting from "./components/Account/MFA/MfaSetting";

moment.locale("sv");

window["isEmpty"] = function (obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

let socket;

const App = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const isAuthenticated = useSelector((state) => !!state?.app?.user?.id);
  // @ts-ignore
  const user = useSelector((state) => state?.app?.user);
  // @ts-ignore
  const intercomData = useSelector((state) => state?.app?.intercom);
  // @ts-ignore
  const mustAcceptTOS = useSelector(
    // @ts-ignore
    (state) => state?.app?.user?.terms_accepted_date == null
  );
  // @ts-ignore
  const forceMfaSetting = useSelector((state) => state?.app?.showMfaSetting);

  const token =
    currentEnv === ENVIRONMENTS.DEV
      ? "" // needs token from alpha for now
      : getToken().token;

  function connectSocket() {
    if (isAuthenticated && token) {
      socket = new WebSocket(`${SOCKET_URL}?Token=${token}`);

      socket.onmessage = function (e) {
        handleSocketUpdates(e, dispatch);
      };

      socket.onclose = function (e) {
        setTimeout(function () {
          connectSocket();
        }, 1000);
      };

      socket.onerror = function (err) {
        socket.close();
      };
    }
  }

  // intercom setup
  React.useEffect(() => {
    // @ts-ignore
    if (!isAuthenticated || !user?.id) return;
    // @ts-ignore

    initIntercom({
      user,
      intercomData,
      isDev: currentEnv !== ENVIRONMENTS.PROD,
    });
  }, [isAuthenticated, user]);

  React.useEffect(() => {
    if (isAuthenticated && token && !socket) {
      connectSocket();
    }

    if (!isAuthenticated && socket) {
      try {
        socket.close();
      } catch (e) {
        console.log("could not close socket");
      }
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (currentEnv === ENVIRONMENTS.ALPHA) {
      addAlphaScript();
    } else if (currentEnv === ENVIRONMENTS.PROD) {
      addBetaScript();
    }
    dispatch(localLogin());
  }, []);

  const handleSetScreenSize = () => {
    dispatch(setScreenSize());
  };

  useEffect(() => {
    handleSetScreenSize();
    window.addEventListener("resize", debounce(handleSetScreenSize, 100));

    return () => {
      window.removeEventListener("resize", debounce(handleSetScreenSize));
    };
  }, []);

  // disable changing number input on scroll
  useEffect(() => {
    document.addEventListener("wheel", function (event) {
      // @ts-ignore

      if (document.activeElement.type === "number") {
        // @ts-ignore

        document.activeElement.blur();
      }
    });
  }, []);

  useEffect(() => {
    if (
      !window.location.origin.includes("localhost") &&
      user?.id &&
      user?.permissions?.customer_permissions?.allow_management === false
    ) {
      clearUserData();
      window.location.href = window.location.origin.replace(
        "management",
        "app"
      );
    }
  }, [user]);

  return (
    <SCTheme theme={theme}>
      <ConnectedRouter history={history}>
        {mustAcceptTOS && <TOSAgreement />}
        {forceMfaSetting && <MfaSetting />}
        <ScrollToTop />
        <ToastList />
        <Routes />
      </ConnectedRouter>
    </SCTheme>
  );
};

export default App;
