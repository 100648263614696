import axios from "axios";
import { formattedToken } from "./localStorage";
import { redirect } from "../store/app/services";
import { clearUserData } from "./localStorage";
import { store } from "../store/store";
import storeConstants from "../store/app/constants";
import { currentEnv, ENVIRONMENTS } from "..";

export const performLogutRedirect = (devSrc) => {
  store.dispatch({ type: storeConstants.LOG_OUT });

  const currentPath = window.location.pathname;

  try {
    window.Intercom("shutdown");
  } catch (e) {
    console.error(e);
  }

  if (currentEnv !== ENVIRONMENTS.PROD) {
    console.log({ devSrc });
  }

  switch (currentEnv) {
    // case ENVIRONMENTS.PROD: {
    //   window.location.replace("https://pigello.se/logga-in");
    //   break;
    // }

    default: {
      if (!window.location.href.includes(`${window.location.origin}/login`)) {
        window.location.replace(
          `${window.location.origin}/login?loggedout=true&next=${currentPath}`
        );
      }
    }
  }
};

function errorRedirect(status) {
  switch (status) {
    case 400:
      return;
    case 401:
      clearUserData();

      performLogutRedirect("401");
      break;
    case 403:
      redirect("/403");
      break;
    case 404:
      redirect("/404");
      break;
    case 409:
      redirect("/409");
      break;
    case 429:
      redirect("/429");
      break;
    default:
      redirect("/error/oops");
      break;
  }
}

const baseUrl = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    return "http://localhost:8000";
  } else if (window.location.host.includes("demo.management.pigello")) {
    return "https://v2alpha.api.pigello.se";
  } else if (window.location.host.includes("test.management.pigello")) {
    return "https://v2alpha.api.pigello.se";
  } else if (window.location.host.includes("v2.app")) {
    return "https://v2api.pigello.se";
  } else {
    return "https://v2api.pigello.se";
  }
};

// list of 401 urls that should not trigger logout
const IGNORE_401_LOGOUT_URLS = ["market/authenticate/"];

export const axiosInstance = axios.create({
  baseURL: baseUrl(),
  timeout: 150000,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = formattedToken();
  return config;
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let status = 500;
    if (error.response) {
      status = error.response.status;
    }

    if (status === 500 || status === 401) {
      let dontLogout = false;
      if (status === 401) {
        // check if 401 is in ignored unaythenticated calls
        dontLogout = IGNORE_401_LOGOUT_URLS.some((url) => {
          if (error.response.config.url.includes(url)) {
            return true;
          }
        });
      }

      if (!dontLogout) {
        errorRedirect(status);
      }
    }

    throw error;
  }
);
